import React, { useContext, useEffect } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { AlternateLinksContext } from './wrapWithI18nProvider'

const MenuMobile = ({ visible, setVisible }) => {
  const
    { t, i18n } = useTranslation('common'),
    alternateLinks = useContext(AlternateLinksContext);


  useEffect(() => {
    if(visible) {
      document.querySelector('body').classList.add('overflow-none')
    } else {
      document.querySelector('body').classList.remove('overflow-none')
    }
  })

  return (
    <div className={!!visible ? "menu-mobile-container open" : "menu-mobile-container"}>
      {alternateLinks &&
        alternateLinks
        .filter(link => link.language !== i18n.language)
        .map((link, i) => [
          i > 0 && " | ",
          <Link
          key={i}
          to={link.path}
          style={{
            textDecoration: `none`,
          }}
          hrefLang={link.language}
          onClick={() => setVisible(false)}
          className="language-link"
          >
              {t(link.language)}
            </Link>,
          ])}
      <ul>
        <li><Link onClick={() => setVisible(false)} to={`/${i18n.language}/work`}>{t("work")}</Link></li>
        <li><Link onClick={() => setVisible(false)} to={`/${i18n.language}/services`}>{t("services")}</Link></li>
        <li><Link onClick={() => setVisible(false)} to={`/${i18n.language}/culture`}>{t("culture")}</Link></li>
        <li><Link onClick={() => setVisible(false)} to={`/${i18n.language}/contact`}>{t("contact")}</Link></li>
      </ul>

      <p>Wee see beauty<br/>and return it enhanced</p>
    </div>
  )
}

export default MenuMobile
