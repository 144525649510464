import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import { useTranslation } from 'react-i18next'
import { document, window } from "browser-monads"
import classNames from "classnames"
import { AlternateLinksContext } from './wrapWithI18nProvider'

import MenuMobile from "./menuMobile"

import Logo from "../assets/svg/logo.svg"

const Header = () => {
  const
    alternateLinks = useContext(AlternateLinksContext),
    { t, i18n } = useTranslation('common'),
    [direction, setDirection] = useState(null),
    [visible, setVisible] = useState(false);

  let
    menuClasses = classNames({
      'menu open': visible === true,
      'menu': visible === false
    });

  let
    curScroll = window.scrollY || document.scrollTop,
    prevScroll = window.scrollY || document.scrollTop,
    curDirection = 0,
    prevDirection = 0;

  const checkScroll = () => {
    curScroll = window.scrollY || document.scrollTop

    if((curScroll > prevScroll) && window.scrollY > 5) {
      curDirection = 2
      setDirection(2)
    } else {
      curDirection = 1
      setDirection(1)
    }

    prevDirection = curDirection;
    prevScroll = curScroll;
  }


  useEffect(() => {
    window.addEventListener('scroll', checkScroll);
  })

  return (
    <header className={ direction === 2 ? "hide" : "show"}>
      <Link style={{zIndex: 99}} to={`/${i18n.language}`}><Logo className={visible === false ? 'logo' : 'logo white'}/></Link>
        <nav>
          <span><Link className="underline" to={`/${i18n.language}/work`}>{t("work")}</Link></span>
          <span><Link className="underline" to={`/${i18n.language}/services`}>{t("services")}</Link></span>
          <span><Link className="underline" to={`/${i18n.language}/culture`}>{t("culture")}</Link></span>
          <span><Link className="underline" to={`/${i18n.language}/contact`}>{t("contact")}</Link></span>
        </nav>

        {alternateLinks &&
          alternateLinks
          .filter(link => link.language !== i18n.language)
          .map((link, i) => [
            i > 0 && " | ",
              <Link
              key={i}
                className="underline language-link"
                to={link.path}
                hrefLang={link.language}
              >
                {t(link.language)}
              </Link>,
          ])
        }

        <div className={menuClasses} onClick={() => setVisible(!visible)}>
          <span className='menu-icon'></span>
          <span className='menu-icon'></span>
        </div>

        <MenuMobile visible={visible} setVisible={setVisible}/>
    </header>
  )
}

export default Header
